<template>
  <div>
    <v-dialog
      v-model="addEditDialog"
      persistent
      max-width="700px"
      transition="slide-y-transition"
    >
      <v-card class="rounded-lg dialog-card-border">
        <v-card-title class="d-flex justify-space-between align-center font-weight-bold">
          <div class="text-center col pa-0">
            {{ editMode ? $t('Update Company') : $t('Add New Company') }}
          </div>

          <v-btn
            fab
            small
            depressed
            @click="closeDialog()"
          >
            <v-icon class="mdil-28px mdil-rotate-45">
              mdil-plus
            </v-icon>
          </v-btn>
        </v-card-title>

        <validation-observer
          ref="theForm"
          v-slot="{ handleSubmit, reset }"
        >
          <form
            autocomplete="off"
            @submit.prevent="handleSubmit(submit)"
            @reset.prevent="reset"
          >
            <v-card-text>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  class="mb-6"
                >
                  <app-image-uploader
                    v-if="addEditDialog"
                    ref="appImageUploader"
                    width="150px"
                    height="150px"
                    classes="rounded-pill"
                    parent-classes="justify-center"
                    label="Company logo"
                    :parent-image-url="formData.logo"
                    @image-selected="handleImageSelect"
                    @is-image-updated="formData.logo_updated = $event"
                  />
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Name')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="formData.name"
                      :label="`* ${$t('Name')}`"
                      outlined
                      dense
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Activity')"
                  >
                    <v-textarea
                      v-model="formData.activity"
                      :label="$t('Activity')"
                      outlined
                      rows="3"
                      dense
                      no-resize
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Company Type')"
                    rules="required"
                  >
                    <v-combobox
                      v-model="formData.company_type_id"
                      :label="`* ${$t('Company Type')}`"
                      :items="companyTypesList"
                      outlined
                      dense
                      :error-messages="errors"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ $t('Press enter to create to record') }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-combobox>
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Governorate')"
                    rules="required"
                  >
                    <v-combobox
                      v-model="formData.governorate_id"
                      :label="`* ${$t('Governorate')}`"
                      :items="governorateList"
                      outlined
                      dense
                      :error-messages="errors"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ $t('Press enter to create to record') }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-combobox>
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('First Bank')"
                    rules="required"
                  >
                    <v-combobox
                      v-model="formData.first_bank_id"
                      :label="`* ${$t('First Bank')}`"
                      :items="banksList"
                      outlined
                      dense
                      :error-messages="errors"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ $t('Press enter to create to record') }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-combobox>
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Account Bank')"
                    rules="required"
                  >
                    <v-combobox
                      v-model="formData.account_bank_id"
                      :label="`* ${$t('Account Bank')}`"
                      :items="banksList"
                      outlined
                      dense
                      :error-messages="errors"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ $t('Press enter to create to record') }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-combobox>
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors, failed }"
                    :name="$t('Business Name Date')"
                    rules="required"
                  >
                    <app-date-picker
                      v-model="formData.name_date"
                      :label="$t('Business Name Date')"
                      :base-value="formData.name_date"
                      no-title
                      :errors="errors"
                      :failed="failed"
                      @value-changed="formData.name_date = $event"
                    />
                  </validation-provider>
                </v-col>

                <!-- <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Business Name Number')"
                    rules="required"
                  >
                    <v-text-field
                      v-model.number="formData.name_number"
                      :label="`* ${$t('Business Name Number')}`"
                      outlined
                      dense
                      type="tel"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col> -->

                <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Capital')"
                    rules="required"
                  >
                    <v-currency-field
                      v-model="formData.capital"
                      :label="`* ${$t('Capital')}`"
                      outlined
                      dense
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Registration Number')"
                  >
                    <v-text-field
                      v-model.number="formData.company_registration_number"
                      :label="$t('Registration Number')"
                      outlined
                      dense
                      type="tel"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors, failed }"
                    :name="$t('Company Registration Date')"
                    rules="required"
                  >
                    <app-date-picker
                      v-model="formData.company_registration_date"
                      :label="$t('Company Registration Date')"
                      :base-value="formData.company_registration_date"
                      no-title
                      :hide-details="!failed"
                      :errors="errors"
                      :failed="failed"
                      @value-changed="formData.company_registration_date = $event"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Manager')"
                  >
                    <v-text-field
                      v-model="formData.manager"
                      :label="$t('Manager')"
                      outlined
                      dense
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="4"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Phone')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="formData.phone"
                      :label="`* ${$t('Phone')}`"
                      outlined
                      dense
                      type="tel"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="4"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Email')"
                  >
                    <v-text-field
                      v-model="formData.email"
                      :label="$t('Email')"
                      outlined
                      dense
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="4"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Website')"
                  >
                    <v-text-field
                      v-model="formData.website"
                      :label="$t('Website')"
                      outlined
                      dense
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <!-- <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Supporter Lawyer')"
                  >
                    <v-text-field
                      v-model="formData.s_lawyer"
                      :label="$t('Supporter Lawyer')"
                      outlined
                      dense
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col> -->

                <!-- <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Supporter Accountant')"
                  >
                    <v-text-field
                      v-model="formData.s_accountant"
                      :label="$t('Supporter Accountant')"
                      outlined
                      dense
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col> -->

                <!-- <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Worker Lawyer')"
                  >
                    <v-text-field
                      v-model="formData.w_lawyer"
                      :label="$t('Worker Lawyer')"
                      outlined
                      dense
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col> -->

                <!-- <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Worker Accountant')"
                  >
                    <v-text-field
                      v-model="formData.w_accountant"
                      :label="$t('Worker Accountant')"
                      outlined
                      dense
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col> -->

                <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Dos Number In Companies')"
                    rules="required"
                  >
                    <v-text-field
                      v-model.number="formData.dos_number_in_co"
                      :label="`* ${$t('Dos Number In Companies')}`"
                      outlined
                      dense
                      type="tel"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Dos Number Place')"
                    rules="required"
                  >
                    <v-select
                      v-model.number="formData.dos_number_place"
                      :label="`* ${$t('Dos Number Place')}`"
                      :items="dosNumberPlacesList"
                      :item-text="item => $t(item.text)"
                      outlined
                      dense
                      :error-messages="errors"
                      :menu-props="{ offsetY: true }"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Dos Number In Companies/General')"
                  >
                    <v-text-field
                      v-model.number="formData.dos_number_in_co_general"
                      :label="$t('Dos Number In Companies/General')"
                      outlined
                      dense
                      type="tel"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Dos Number In Tax Office')"
                  >
                    <v-text-field
                      v-model.number="formData.dos_number_in_tax_office"
                      :label="$t('Dos Number In Tax Office')"
                      outlined
                      dense
                      type="tel"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Real Beneficiary Form Number')"
                    rules="required"
                  >
                    <v-text-field
                      v-model.number="formData.real_beneficiary_form_number"
                      :label="`* ${$t('Real Beneficiary Form Number')}`"
                      outlined
                      dense
                      type="tel"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Social Security Employee Number')"
                    rules="required"
                  >
                    <v-text-field
                      v-model.number="formData.social_security_number_employee"
                      :label="`* ${$t('Social Security Employee Number')}`"
                      outlined
                      dense
                      type="tel"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <!-- 
                <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('SL')"
                  >
                    <v-text-field
                      v-model="formData.sl"
                      :label="$t('SL')"
                      outlined
                      dense
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col> -->

                <!-- <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('FCC')"
                  >
                    <v-text-field
                      v-model="formData.fcc"
                      :label="$t('FCC')"
                      outlined
                      dense
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col> -->

                <!-- <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors, failed }"
                    :name="$t('Accountant Contract Date')"
                  >
                    <app-date-picker
                      v-model="formData.accountant_contract_date"
                      :label="$t('Accountant Contract Date')"
                      :base-value="formData.accountant_contract_date"
                      no-title
                      :errors="errors"
                      :failed="failed"
                      @value-changed="formData.accountant_contract_date = $event"
                    />
                  </validation-provider>
                </v-col> -->

                <!-- <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors, failed }"
                    :name="$t('Lawyer Contract Date')"
                  >
                    <app-date-picker
                      v-model="formData.lawyer_contract_date"
                      :label="$t('Lawyer Contract Date')"
                      :base-value="formData.lawyer_contract_date"
                      no-title
                      :errors="errors"
                      :failed="failed"
                      @value-changed="formData.lawyer_contract_date = $event"
                    />
                  </validation-provider>
                </v-col> -->

                <!-- <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Company Business No.')"
                  >
                    <v-text-field
                      v-model.number="formData.company_business_no"
                      :label="$t('Company Business No.')"
                      outlined
                      dense
                      type="tel"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col> -->

                <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Unique Company Business No.')"
                  >
                    <v-text-field
                      v-model.number="formData.manager_shareholder_business_no"
                      :label="$t('Unique Company Business No.')"
                      outlined
                      dense
                      type="tel"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider />

            <v-card-actions class="py-3">
              <app-form-tip />

              <v-spacer />

              <v-btn
                depressed
                outlined
                small
                @click="closeDialog()"
              >
                {{ $t('Cancel') }}
              </v-btn>

              <v-btn
                type="submit"
                color="primary"
                depressed
                small
              >
                <v-icon left>
                  mdil-check
                </v-icon>
                <span class="pe-2">
                  {{ editMode ? $t('Save') : $t('Add') }}
                </span>
              </v-btn>
            </v-card-actions>
          </form>
        </validation-observer>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  props: {
    addEditDialog: { type: Boolean, default: false },
    formData: { type: Object, default: () => { } },
    editMode: { type: Boolean, default: false },
  },

  computed: {
    ...mapGetters([
      'companyTypesList',
      'banksList',
      'governorateList',
      'dosNumberPlacesList',
    ])
  },

  methods: {
    handleImageSelect(imageFIle) {
      this.formData.logo = imageFIle
      if (this.editMode) {
        let fd = new FormData()
        fd.append('logo', this.formData.logo)
        axios.post(`company/${this.formData.id}/update-logo/`, fd).then(() => {
          this.$_notify('Updated successfully')
        })
      }
    },

    closeDialog() {
      this.$refs.theForm.reset()
      this.$emit('close')
    },

    submit() {
      if (this.editMode) {
        axios.put(`company/${this.formData.id}/`, this.formData).then(() => {
          this.$_notify('Updated successfully')
          this.closeDialog()
          this.$emit('update-table-data')
        })
      } else {
        axios.post('company/', this.formData).then(res => {
          // if logo uploaded
          if (this.formData.logo) {
            let fd = new FormData()
            fd.append('logo', this.formData.logo)
            axios.post(`company/${res.data.id}/update-logo/`, fd)
          }
          this.$_notify('Added successfully')
          this.closeDialog()
          this.$emit('reset-table-data')
        })
      }
    }
  },
}
</script>
